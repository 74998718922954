<template>
  <div>
    <template v-if="list && list.length > 1">
      <div @click="showPicker()" class="user-device-list">
        {{ form.userName }}      
        <van-icon class="down-icon" name="arrow-down" />
      </div>
      <van-popup v-model="show" :lazy-render="false" round position="bottom">
        
        <van-picker
          @confirm="onFinish"
          show-toolbar
          :columns="list"
          ref="picker"
          :value-key="'name'"
          @cancel="show = false"
          :default-index="defaultIndex"  
        />
      </van-popup>
    </template>

    <template v-if="!list"><span>&nbsp;</span></template>
  </div>
</template>

<script>
import { Icon, Cascader, Popup, Picker } from 'vant'
import { forEach } from 'lodash';
export default {
  components: { [Icon.name]: Icon, [Cascader.name]: Cascader, [Popup.name]: Popup, [Picker.name]: Picker },
  props: ['list', 'form'],
  data() {
    return {
      show: false,
      show2: false,
      defaultIndex: 0,      
    }
  },
  created() {
    forEach(this.list, (v, k) => {
      if(this.form.deviceUserId === v.uuid) {
        this.defaultIndex = k;      
      }  
    })
  },
  methods: {
    onFinish(value, index) {
      this.show = false; 
      // this.defaultIndex = index;
      console.log(value, index, '--onFinish--');
      this.$emit('changeForm', {
        deviceUserId: value.uuid,        
        userName: value.name
      })
    },
    showPicker() {
      this.show = true;
      this.$nextTick(() => {
        this.$refs.picker.setColumnValue(0,this.form.userName);
      })
    }
  },
}
</script>

<style lang="less" scoped>
.user-device-list {
  font-size: .23rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #FFF;
  padding: .12rem .2rem;
  // height: .58rem;
  background: #0074FE;
  border-radius: .29rem;

  .down-icon {
    font-size: .23rem;
    color: #FFF;
  }
}
</style>